.banner-text-service {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.services .card-text {
    opacity: 0;
    transition: .5s ease;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    text-align: center;
    transform: translateY(75%);
}

.services .card:hover .card-text {
    opacity: 1;
    background: #171515;
    padding: 8px;
}

.services .card:hover img {
    filter: blur(4px);
    -webkit-filter: blur(4px);
}

.services .line-1 {
    position: absolute;
    left: 0;
    top: -8rem;
    z-index: -5;
}

.services .bell-1 {
    position: absolute;
    top: -2rem;
    right: 20rem;
}

.services .star-1 {
    position: absolute;
    right: 0;
    top: 0;
}

.services .ellipse-1 {
    position: absolute;
    top: 0;
    left: 8rem;
}

.services .ellipse-2 {
    position: absolute;
    top: 5rem;
    right: 8rem;
}