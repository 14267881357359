.case-study-content .text-content {
  position: absolute;
  left: 50%;
  top: 35%;
}

.tube-case-study {
  position: absolute;
  left: 0;
  bottom: 0;
}

.round-case-study {
  position: absolute;
  right: 0;
  top: 10rem;
  z-index: 1;
}

.bg-portfolio-1 {
  background: #eba34f;
}

.bg-portfolio-2 {
  background: #ed4c8f;
}

.bg-portfolio-3 {
  background: #64abeb;
}

.bg-portfolio-4 {
  background: #b263e3;
}
