.portfolio-banner {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 20%;
  bottom: 0%;
}

.portfolio .text-top {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.portfolio .text-bottom {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}

.portfolio .img-laptop {
  position: absolute;
  /* margin: auto; */
  left: 50%;
  transform: translate(-50%, -150%);
}

.portfolio .img-code {
  position: absolute;
  top: 1rem;
  right: 0;
}

.portfolio .img-css {
  position: absolute;
  bottom: 0;
  left: 0;
}

.img-bell {
  position: absolute;
  right: 0;
  top: 0;
}

.img-lightbulb {
  position: absolute;
  left: 0;
  bottom: 0;
}

.img-star {
  position: absolute;
  right: 0;
  top: 0;
}

.img-heartshape {
  position: absolute;
  left: 0;
  bottom: 0;
}

.img-chat-bubbles {
  position: absolute;
  right: 0;
  top: 0;
}

.img-rocket {
  position: absolute;
  left: 0;
  bottom: 0;
}

.img-tube {
  position: absolute;
  left: 0;
  top: -35rem;
}

.img-box3d {
  position: absolute;
  right: 0;
  top: -20rem;
  z-index: -1;
}

.half-ellipse-portfolio {
  position: absolute;
  right: 0;
  top: 10rem;
}

.ellipse-full-portfolio {
  position: absolute;
  left: 4rem;
  top: 10rem;
}