@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Epilogue&display=swap");
body {
  margin: 0;
  font-family: "Montserrat", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-black {
  background: #171515;
}

.bg-linear-1 {
  background: linear-gradient(
    96.2deg,
    rgba(253, 160, 97, 0.9) 23.01%,
    rgba(233, 64, 133, 0.9) 85.35%
  );
  backdrop-filter: blur(4px);
}

.bg-linear-2 {
  background: linear-gradient(
    97.97deg,
    rgba(124, 208, 215, 0.9) 30.53%,
    rgba(58, 129, 182, 0.9) 88.39%
  );
  backdrop-filter: blur(4px);
}

.bg-linear-3 {
  background: linear-gradient(
    105.1deg,
    rgba(222, 91, 114, 0.9) 27.07%,
    rgba(131, 72, 187, 0.9) 83.78%
  );
  backdrop-filter: blur(4px);
}

.bg-linear-4 {
  background: linear-gradient(
    109.45deg,
    rgba(248, 190, 18, 0.9) 31.97%,
    rgba(230, 90, 35, 0.9) 79.17%
  );
  backdrop-filter: blur(4px);
}

.bg-linear-5 {
  background: linear-gradient(
    110.32deg,
    rgba(139, 216, 170, 0.9) 36.49%,
    rgba(4, 116, 101, 0.9) 97.15%
  );
  backdrop-filter: blur(4px);
}

.bg-linear-6 {
  background: linear-gradient(
    113.34deg,
    rgba(99, 164, 223, 0.9) 36.87%,
    rgba(92, 46, 196, 0.9) 93.01%
  );
  backdrop-filter: blur(4px);
}

.font-epilogue {
  font-family: "Epilogue", sans-serif;
}

.fw-500 {
  font-weight: 500;
}

.fs-12 {
  font-size: 12px;
}

.fs-16 {
  font-size: 1rem;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-24 {
  font-size: 24px;
}

.fs-30 {
  font-size: 30px;
}

.fs-32 {
  font-size: 32px;
}

.fs-40 {
  font-size: 40px;
}

.fs-48 {
  font-size: 48px;
}

.fs-52 {
  font-size: 52px;
}

.fs-56 {
  font-size: 56px;
}

.fs-62 {
  font-size: 62px;
}

.fs-82 {
  font-size: 82px;
}

.text-black {
  color: #171515;
}

.text-honeycomb {
  color: #dea04f;
}

.text-justify {
  text-align: justify;
}

.btn-transparent {
  background-color: Transparent;
  border-color: white;
}

.btn-transparent-black {
  background-color: transparent;
  border-color: black;
}

footer .decoration {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 85%;
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(90deg, #e93f89 1.55%, #fe984e 94.69%);
  border-radius: 4px;
  padding: 50px 40px;
}

footer .content {
  padding: 180px 100px 48px;
}

.sidebar {
  background-color: white;
  position: fixed;
  width: 250px;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 1;
  transition: all 0.5s;
  text-align: right;
}

.sidebar ul li a {
  text-decoration: none;
  color: black;
  font-size: 25px;
  font-weight: 700;
}

.sidebar ul {
  list-style-type: none;
}

.sidebar ul li {
  margin: 1rem 0;
}

.sidebar li:hover {
  background: linear-gradient(
    96.2deg,
    rgba(253, 160, 97, 0.9) 23.01%,
    rgba(233, 64, 133, 0.9) 85.35%
  );
  backdrop-filter: blur(4px);
  padding: 0 4px;
  border-radius: 2px;
}

.sidebar ul li a:hover {
  color: white;
}

/* Home Section  */
.home .accordion-item {
  border-top: 3px solid #B059AD;
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.home .content {
  transition: transform .2s;
}

.home .content:hover {
  transform: scale(1.1);
  z-index: 5;
}

.socmed-picture {
  position: absolute;
  top: 15%;
}

.banner-text {
  position: absolute;
  top: 25%;
}

.banner-text h1 {
  width: 45%;
}

.text-banner-small {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.services-text {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #ffffff;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  transition: all 0.5s;
}

/* .services .content:hover .services-text {
  height: 100%;
  transition: all 0.5s;
}

.services .content .description-lg {
  margin-bottom: -25%;
}

.services .content:hover .description-lg, .services .content:hover .description, .services .content .description-sm {
  margin-bottom: 0;
}

.services .content .description {
  margin-bottom: -25%;
}

.services .content .description-sm {
  margin-bottom: -46%;
} */

.home .party-popper {
  position: absolute;
  right: 0;
  top: -10rem;
}

.home .heart-shape {
  position: absolute;
  left: 50%;
  top: -7rem;
}

.home .ellipse-1 {
  position: absolute;
  right: 30%;
  top: 3%;
}

.home .ellipse-2 {
  position: absolute;
  top: 0;
  left: 20%;
}

.home .half-round-1 {
  position: absolute;
  top: -15rem;
  left: 0;
}

.home .half-round-2 {
  position: absolute;
  right: 0;
}

.home .ornament {
  position: absolute;
  left: 0;
  top: 8rem;
}

.home .star-1 {
  position: absolute;
  right: 0;
  top: -6rem;
}

.home .line-1 {
  position: absolute;
  left: 0;
  top: -3rem;
  z-index: 1;
}

.home .bell-1 {
  position: absolute;
  right: 0;
  top: -8rem;
}

.home .question-mark-1 {
  position: absolute;
  left: 50%;
}

.home .question-mark-2 {
  position: absolute;
  left: 0;
  top: -15rem;
}

/* About Us Section */
.about-us-banner .text {
  position: absolute;
  left: 10%;
  top: 30%;
  width: calc(100%/3);
}

.about-us-banner .img {
  position: absolute;
  right: 10%;
  top: 15%;
}

.about-us .text-head {
  font-size: 52px;
  font-weight: 700;
}

.our-client {
  padding: 1rem 8rem 8rem 8rem;
}

.our-client .img-container:hover {
  border: 3px solid #d3308b;
  border-radius: 12px;
}

.about-us .line-2 {
  position: absolute;
  right: 0;
}

.about-us .party-popper {
  position: absolute;
  right: 0;
}

.about-us .half-round-3d {
  position: absolute;
  top: -15rem;
  left: 0;
}

.about-us .half-round-2 {
  position: absolute;
  right: 0;
}

.about-us .ellipse-1 {
  position: absolute;
  right: 30%;
  top: 3%;
}

.about-us .ellipse-2 {
  position: absolute;
  right: 1rem;
}

.about-us .polygon-2 {
  position: absolute;
  left: 0;
}

.about-us .star-sm {
  position: absolute;
  right: 0;
}

.about-us .graph-1 {
  position: absolute;
  left: 0;
}

.about-us .bell {
  position: absolute;
  right: 0;
  top: -8rem;
}

.about-us .ornament-4 {
  position: absolute;
  right: 1rem;
}

/* Case Study section  */
.case-study-banner {
  position: absolute;
  top: calc(100%/8);
  width: 100%;
}

/* News Section  */
.news-banner .text {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 50vh;
  text-align: center;
}

.news-banner .image {
  position: absolute;
  bottom: 0;
  right: 10%;
}

.news-lightbulb {
  position: absolute;
  top: 8rem;
  left: 0;
}

.news-ellipse-2 {
  position: absolute;
  right: 2rem;
  top: 20rem;
}

.news-section a {
  text-decoration: none;
  color: inherit;
}

/* MEDIA QUERY SECTION */

/* Mobile Devices */
@media (min-width: 320px) and (max-width: 480px) {
  /* Sidebar Section  */
  .sidebar {
    width: 100%;
    text-align: left;
  }
  
  /* Home Section  */
  /* .home-banner .banner-text {
    top: 15%;
  } */

  .home-banner .text-banner-small {
    top: 50%;
  }

  .home-banner .banner-text h1 {
    font-size: 32px;
    font-weight: 700;
    width: 100%;
    text-align: center;
  }

  .home-banner .text-banner-small span {
    font-size: 10px;
  }

  .home .font-epilogue span {
    font-size: 1.5rem;
    text-align: center;
  }

  /* About Us Section */
  .about-us-banner .text {
    width: 100%;
    top: 0;
    left: 0;
    padding: 1rem;
  }

  .about-us-banner h1 {
    font-size: 32px;
  }

  .about-us-banner h5 {
    font-size: .9rem;
  }

  .about-us .text-head {
    font-size: 2rem;
    text-align: center;
  }

  .about-us .align-self-center p {
    font-size: 1rem;
    text-align: center;
  }

  .our-client {
    padding: 1rem;
  }

  /* Services Section */
  .banner-text-service h1 {
    font-size: 16px;
    font-weight: 700;
  }

  .banner-text-service p {
    font-size: 12px;
  }

  .banner-text-service i {
    font-size: 9px;
  }

  .services .vaya-description {
    font-size: 1rem;
    padding-top: 8px;
  }

  /* News Section  */
  .news-banner .text {
    bottom: 8vh;
  }

  .news-banner .text h1 {
    font-size: 2rem;
  }

  /* Contact Us Section */
  .contact-us-banner h2 {
    font-size: 2rem;
  }

  .contact-us-banner p {
    font-size: 8px;
    padding: 0;
  }

  .contact-us h5 {
    font-size: 1rem;
  }

  .contact-us .contact-desc h5 {
    font-size: 1rem;
  }

  .contact-us .contact-desc span {
    font-size: 12px;
  }

  /* Footer Section  */
  footer .decoration {
    padding: 1rem;
    top: 0;
    bottom: unset;
  }

  footer .decoration p {
    font-size: 11px;
  }

  footer .decoration button {
    height: auto;
  }

  footer .content {
    padding: 120px 30px;
  }
}

/* Tablet */
@media (min-width: 481px) and (max-width: 768px) {
  
}

/* Laptop */
@media (min-width: 769px) and (max-width: 1024px) {

  /* Home Section  */
  .home-banner .banner-text h1 {
    font-size: 2rem;
  }

  .home-banner .banner-text span {
    font-size: 1rem;
  }

  .services .content .description-lg {
    margin-bottom: -30%;
  }
}

/* Desktop */
@media (min-width: 1025px) and (max-width: 1200px) {
  .services .content .description-lg {
    margin-bottom: -35%;
  }

  .services .content .description {
    margin-bottom: -52%;
  }
}

/* Extra Large Screen  */
@media (min-width: 1201px) {
  /* .services .content .description-lg {
    margin-bottom: -30%;
  } */
}

/* END OF MEDIA QUERY SECTION */
