.contact-us-banner {
  position: absolute;
  top: 15%;
}

.pin-location {
  position: absolute;
  top: 0;
  left: -25%;
}

.btn-contact-us {
  background: linear-gradient(
    110.32deg,
    rgba(139, 216, 170, 0.9) 36.49%,
    rgba(4, 116, 101, 0.9) 97.15%
  );
  border-radius: 4px;
  color: white;
}

.contact-form .form-control {
  background: #f8f8f8;
}

.contact-half-round-3d {
  position: absolute;
  left: 0;
  top: -15rem;
  z-index: -1;
}

.contact-box-3d {
  position: absolute;
  right: 0;
  top: -20rem;
}

.contact-tube-3d {
  position: absolute;
  left: 0;
  top: 0;
}

.contact-half-star {
  position: absolute;
  right: 0;
  top: 0;
}

